<template>
  <section>
    <b-card>
      <b-tabs>
        <b-tab active title="Pertanyaan yang dipilih">
          <!-- search input -->
          <b-row>
            <b-col md="3" v-for="(monitor, id) in rows2" :key="id">
              <ul>
                <li style="color: black; font-size: smaller;">
                    {{ monitor.nama_category }} - {{ monitor.nama_study }} =
                    {{ monitor.result }}
                </li>
              </ul>
            </b-col>
          </b-row>

          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns2"
            :rows="rows"
            :search-options="{
              enabled: false,
              externalQuery: searchTerm,
            }"
            :select-options="{
              enabled: false,
              selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
              disableSelectInfo: false, // disable the select info panel on top
              selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Action -->
              <span
                v-if="props.column.field === 'id'"
                class="btn-icon text-center"
              >
                {{ props.index + 1 }}
              </span>
              <!-- Column: Action -->
              <span
                v-else-if="props.column.field === 'action'"
                class="btn-icon text-center"
              >
                <b-button
                  variant="gradient-danger"
                  class="btn-icon"
                  @click="deleteData(props.row.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '20', '50']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    :align="pagnation_posisition"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-tab>
        <b-tab title="Semua Daftar Pertanyaan">
          <b-card>
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table
              :columns="columns"
              :rows="listQuestion"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
              }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                disableSelectInfo: false, // disable the select info panel on top
                selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <span
                  v-if="props.column.field === 'id'"
                  class="btn-icon text-center"
                >
                  {{ props.index + 1 }}
                </span>
                <!-- Column: Action -->
                <span
                  v-else-if="props.column.field === 'action'"
                  class="btn-icon text-center"
                >
                  <b-button
                    variant="gradient-success"
                    class="btn-icon"
                    @click="addData(props.row.id)"
                  >
                    <feather-icon icon="PlusCircleIcon" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '20', '50']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      :align="pagnation_posisition"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card>
  </section>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BCardHeader,
  BTooltip,
  BCardTitle,
  BNav,
  BNavItem,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table/src";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
export default {
  components: {
    VueGoodTable,
    BCard,
    ToastificationContent,
    BAvatar,
    Swal,
    BBadge,
    vSelect,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCardHeader,
    BNav,
    BNavItem,
    BTooltip,
    BCardTitle,
    BCol,
  },
  data() {
    return {
      selected: "right",
      pagnation_posisition: "right",
      pageLength: 10,
      columns: [
        {
          label: "No",
          field: "id",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Pertanyaan",
          field: "question",
          filterOptions: {
            enabled: true,
            placeholder: "Cari Pertanyaan",
          },
          width: "60%",
        },
        {
          label: "Kategori",
          field: "nama_category",
          filterOptions: {
            enabled: true,
            placeholder: "Cari Kategori",
          },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Pelajaran",
          field: "nama_study",
          filterOptions: {
            enabled: true,
            placeholder: "Cari Pelajaran",
          },
          thClass: "text-center",
          tdClass: "text-center",
        },

        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      columns2: [
        {
          label: "No",
          field: "id",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Pertanyaan",
          field: "nama_question",
          filterOptions: {
            enabled: true,
            placeholder: "Cari Pertanyaan",
          },
          width: "60%",
        },
        {
          label: "Kategori",
          field: "nama_category",
          filterOptions: {
            enabled: true,
            placeholder: "Cari Kategori",
          },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Pelajaran",
          field: "nama_study",
          filterOptions: {
            enabled: true,
            placeholder: "Cari Pelajaran",
          },
          thClass: "text-center",
          tdClass: "text-center",
        },

        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      rows: [],
      rows2: [],
      searchTerm: "",
      listQuestion: [],
      questions_id: "",
      plans_id: this.$route.params.id,
      id: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };
      return (status) => statusColor[status];
    },
  },
  async mounted() {
    this.checkAuth();
    await this.getData();
    await this.getQuestion();
    await this.getDataMonitor();
  },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      var levelCheck = localStorage.getItem("Ulevel");
      if (sessionCheck == null || sessionCheck == "") {
                    this.$router.push({ name: "login" });
      }
      else if(levelCheck != 2){
            this.$router.push({ name: "dashboard" });
      }
    },
    async getData() {
      await this.$http
        // .get(process.env.VUE_APP_BACKEND_URL + "registerquestions/view")
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `registerquestions/view/${this.$route.params.id}`
        )
        .then((res) => {
          this.rows = res.data.data;
        });
    },
    async addData(questionId) {
      const formData = new FormData();
      formData.append("questions_id", (this.questions_id = questionId));
      formData.append("plans_id", this.plans_id);
      await this.$http
        .post(
          process.env.VUE_APP_BACKEND_URL + "registerquestions/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          // console.log("mikki", res.data.success);
          if (res.data.success == false) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "xIcon",
                  text: "Data sudah Ada",
                  variant: "danger",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Berhasil Ubah Data",
                  variant: "success",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
          // this.$router.push({ name: "list_plan" });
          // this.$router.go();
          // this.nama = "";
          // this.deskripsi = "";
          // this.catatan = "";
          // this.questions_id = "";
          // this.id_event = "";
          // this.durasi = "";
          // this.jumlah_soal = "";
          // this.status = "";
          this.getData();
          this.getDataMonitor();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getQuestion() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "questions/view")
        .then((res) => {
          this.listQuestion = res.data.data;
        });
    },
    async getDataMonitor() {
      await this.$http
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `registerquestions/monitor_question/${this.$route.params.id}`
        )
        .then((res) => {
          this.rows2 = res.data.data;
        });
    },
    async deleteData(id) {
      await Swal.fire({
        title: "Ingin Hapus Data?",
        text: "Data akan dihapus?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .delete(
                process.env.VUE_APP_BACKEND_URL +
                  `registerquestions/delete/${id}`
              )
              .then((result) => {
                this.getData();
                this.getDataMonitor();
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Hapus Data",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Hapus Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
